<template>
  <b-overlay
    variant="white"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
    :show="isLoading"
  >
    <validation-observer
      ref="evaluateLevelsSaveFormRef"
      #default="{invalid}"
    >
      <b-modal
        id="saveModal"
        centered
        size="lg"
        :title="isCreated ? 'Thêm thang điểm đánh giá tổng quan' : 'Cập nhật thang điểm đánh giá tổng quan'"
        no-close-on-backdrop
        @show="onShow"
        @hide="onHide"
      >
        <b-form>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
            <!-- {{ evaluateLevelsComputed[0].minimumPercent }} -->
              <b-form-group label-for="name">
                <template v-slot:label>
                  Tên thang điểm đánh giá tổng quan <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Tên thang điểm đánh giá tổng quan"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    v-model="targetData.name"
                    name="name"
                    placeholder="Nhập tên mức thang"
                    :state="getElementState(errors)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
            <b-form-group label-for="orderNo">
              <template v-slot:label>
                Mã thang điểm đánh giá tổng quan <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Mã thang điểm đánh giá tổng quan"
                rules="required"
              >
                <b-form-input
                  id="code"
                  v-model="targetData.code"
                  name="code"
                  placeholder="Nhập mã thang điểm đánh giá tổng quan"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
            <b-form-group label-for="status">
              <template v-slot:label>
                Trạng thái <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Trạng thái"
                rules="required"
              >
                <v-select
                  v-model="targetData.status"
                  :options="statusOptions"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            </b-col>
          </b-row>
          <div>
            <b-table striped responsive class="mb-0" :items="targetData.evaluateLevels" :fields="evaluate_level_fields">
              <template v-slot:cell(index)="data">
                {{ data.index + 1 }}
              </template>
              <template #cell(name)="data">
                <validation-provider
                  #default="{ errors }"
                  :name="'Tên mức thang ' + (data.index + 1)"
                  :rules="'required'"
                  :id="'level_name_' + data.index + '_wrap'"
                >
                  
                  <b-form-input
                    :id="'input_level_name_' + data.index"
                    v-model="data.item.name"
                    placeholder="Tên mức thang"
                    type="text"
                    :state="getElementState(errors)"
                  />
                  <small class="text-danger" >{{ errors[0] }}</small>
                </validation-provider>
              </template>
              <template #cell(minimumPercent)="data">
                <validation-provider
                  #default="{ errors }"
                  :name="'Phần trăm mức thang ' + (data.index + 1)"
                  :rules="'required|max-value:100'"
                  :id="'minimum_percent_' + data.index + '_wrap'"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      :id="'input_minimum_percent_' + data.index"
                      v-model="data.item.minimumPercent"
                      placeholder="Phần trăm"
                      @keydown="validateKeyDown"
                      type="number"
                      :state="getElementState(errors)"
                    />
                    <b-input-group-append is-text>
                      %
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger" >{{ errors[0] }}</small>
                </validation-provider>
              </template>
              <template #cell(action)="data">
                <feather-icon
                  icon="Trash2Icon"
                  class="mr-25 small-icon"
                  @click="removeItem(data.index)"
                />
              </template>
            </b-table>
          </div>
        </b-form>
        <div>
          <b-col cols="12">
            <hr>
          </b-col>
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              @click="repeatAgain"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-25"
              />
              <span>Thêm mới 1 mức thang</span>
            </b-button>
        </div>
        <template #modal-footer>
          <div class="w-100 d-flex justify-content-end">
            <b-button
              v-show="isCreated"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              :disabled="invalid"
              @click="onSave"
            >
              <span class="text-right">
                <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
              </span>
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              :disabled="invalid"
              @click="onSave('hide')"
            >
              <span class="text-right">
                <feather-icon icon="CheckIcon" /> Lưu lại
              </span>
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              @click="$bvModal.hide('saveModal')"
            >
              <span class="text-right">
                <feather-icon icon="XIcon" /> Hủy
              </span>
            </b-button>
          </div>
        </template>
      </b-modal>
    </validation-observer>
  </b-overlay>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BModal, BOverlay, BFormInvalidFeedback, BButton, BRow, BCol, BFormCheckbox, BTable, BFormTextarea, BInputGroupAppend, BInputGroup,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, minValue } from '@validations'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import * as events from 'events'
import { getUser } from '@/auth/utils'


export default {
  name: 'CriteriaSave',
  components: {
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BTable,
    BFormTextarea,
    BInputGroupAppend,
    BInputGroup,
  },
  directives: {
    Ripple,
  },
  props: {
    dataSource: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      evaluate_level_fields: [
        { key: 'index', label: '#' },
        { key: 'name', label: 'Tên mức thang', thStyle: { width: "50%" } },
        { key: 'minimumPercent', label: 'Lớn hơn hoặc bằng', thStyle: { width: "30%" }, thClass: 'text-center', tdClass: 'text-center', },
        { key: 'action', label: '', thStyle: { width: "15%" }, },
      ],
      targetData: {
        id: null,
        organizationId: getUser().orgId,
        name: '',
        code: '',
        status: 0,
        evaluateLevels: [],
      },
      required,
      minValue
    }
  },
  computed: {
    events() {
      return events
    },
    ...mapGetters({
      evaluateTypes: 'setOfEvaluateLevel/evaluateTypes',
      evaluateLevelsComputed: 'setOfEvaluateLevel/evaluateLevels',
      statuses: 'setOfEvaluateLevel/statuses',
    }),
    statusOptions() {
      return this.statuses.map(item => ({ value: item.value, label: item.label }))
    },
    isCreated() {
      return !this.dataSource
    },
  },
  methods: {
    ...mapActions({
      createDataSource: 'setOfEvaluateLevel/createDataSource',
      updateDataSource: 'setOfEvaluateLevel/updateDataSource',
      getEvaluateLevels: 'setOfEvaluateLevel/getEvaluateLevels',
    }),
    validateKeyDown() {
      // Check if the pressed key is a number or not
      if (!/^\d$|^Backspace$|^Arrow(Up|Down)$/.test(event.key)) {
        // If not a number, suppress the default event and do not allow input
        event.preventDefault();
      }
    },
    repeatAgain() {
      this.targetData.evaluateLevels.push({
        name: '',
        minimumPercent: 0,
      })
      setTimeout(() => {
        this.$refs.evaluateLevelsSaveFormRef.validate()
      }, 50)
    },
    removeItem(index) {
      this.targetData.evaluateLevels.splice(index, 1)
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onShow() {
      this.isLoading = true
      if (this.dataSource) {
        await this.getEvaluateLevels(this.dataSource.id)
        this.targetData = this.dataSource
        this.targetData.evaluateLevels = this.evaluateLevelsComputed
      }
      this.isLoading = false
    },
    onHide() {
      this.$refs.evaluateLevelsSaveFormRef.reset()
      this.targetData = {
        id: null,
        organizationId: getUser().orgId,
        name: '',
        code: '',
        status: 0,
        evaluateLevels: [],
      }
    },
    async onSave(type = null) {
      if(this.targetData.evaluateLevels.length === 0) {
        this.showDanger('Bạn phải tạo ít nhất 1 mức thang')
        return
      }
      if(this.hasDuplicate(this.targetData.evaluateLevels, 'minimumPercent')) {
        this.showDanger('Bạn không thể nhập 2 mức thang có cùng giá trị phần trăm')
        return
      }
      const valid = this.$refs.evaluateLevelsSaveFormRef.validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = this.isCreated
            ? await this.createDataSource(this.targetData)
            : await this.updateDataSource(this.targetData)
          if (response) {
            const {
              isSuccessful,
              message,
            } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('saveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
    showDanger(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    },
    hasDuplicate(array, propertyName) {
      const countMap = {}; // Sử dụng để đếm số lần xuất hiện của giá trị thuộc tính
      let hasDuplicate = false; // Biến kiểm tra có trùng lặp hay không

      for (const obj of array) {
        const propertyValue = obj[propertyName];

        // Nếu giá trị thuộc tính đã xuất hiện trước đó, tăng biến đếm
        if (countMap[propertyValue]) {
          countMap[propertyValue]++;
        } else {
          countMap[propertyValue] = 1;
        }

        // Nếu biến đếm đạt đến 2, đặt hasDuplicate thành true và thoát khỏi vòng lặp
        if (countMap[propertyValue] === 2) {
          hasDuplicate = true;
          break;
        }
      }
      return hasDuplicate;
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.option-index {
  width: 20px;
}
</style>
